import React from "react";
import biz from "../../../static/images/biz.jpg";
import Evm from "../../../static/images/evm.jpg";
import Doc from "../../../static/images/writing.jpg";
import acturial from "../../../static/images/insurence1.jpg";

export default function SectionBlog() {
  return (
    <div>
      <section
        class="relative py-20 overflow-hidden bg-white"
        style={{ fontFamily: "poppins" }}
      >
        <img
          class="absolute top-0 right-0 xl:mt-10 -mr-24 lg:-mr-0"
          src="saturn-assets/images/blog/star-circle-right.svg"
          alt=""
        />
        <div class="relative container px-4 mx-auto">
          <div class="max-w-xl lg:max-w-7xl mx-auto">
            <div class="max-w-2xl mx-auto mb-15 text-center">
              <span class="inline-block py-1 px-3 mb-4 text-xs font-semibold text-blue-900 bg-white rounded-full">
                OUR BLOG
              </span>
              <h1 class="font-heading text-4xl xs:text-3xl md:text-4xl font-bold mb-8 text-blue-900">
                <span>Latest from the Blogs</span>
              </h1>
            </div>
            <div class="flex flex-wrap -mx-4 mb-18">
              <div class="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
                <a class="block group w-full" href="/blogs/roleofactuaries/">
                  <img
                    class="block w-full mb-5 rounded-lg"
                    src={acturial}
                    alt=""
                  />
                  <span class="block text-gray-500 mb-3">
                    Raunak Jha | Insurance
                  </span>
                  <h4 class="text-3xl font-semibold text-gray-900 group-hover:text-blue-900 mb-5">
                    Parametric Insurance – Role of Actuaries
                  </h4>
                  <p class="max-w-xl text-lg text-gray-500">
                    Parametric insurance also known as Index based insurance, is
                    an emerging and non-traditional way of transferring risks.
                  </p>
                </a>
              </div>
              <div class="w-full lg:w-1/2 px-4">
                <a
                  class="md:flex group mb-8"
                  href="/blogs/UnderwritingScoringModel"
                >
                  <img class="w-48 h-40 rounded-lg" src={Doc} alt="" />
                  <div class="mt-4 md:mt-0 md:ml-6 pt-2">
                    <span class="block text-gray-500 mb-2">
                      Underwriting | Tamanna Goel
                    </span>
                    <h4 class="text-xl font-semibold text-gray-900 group-hover:text-blue-900">
                      Underwriting – An Introduction from Actuarial Perspective
                    </h4>
                  </div>
                </a>
                <a
                  class="md:flex group mb-8"
                  href="/blogs/UnderwritingScoringModel"
                >
                  <img class="w-48 h-40 rounded-lg" src={Doc} alt="" />
                  <div class="mt-4 md:mt-0 md:ml-6 pt-2">
                    <span class="block text-gray-500 mb-2">
                      Underwriting | Swargesh Tripathi{" "}
                    </span>
                    <h4 class="text-xl font-semibold text-gray-900 group-hover:text-blue-900">
                      Underwriting Scoring Models
                    </h4>
                  </div>
                </a>
                <a class="md:flex group mb-8" href="/blogs/EnterpriseRisk">
                  <img class="w-48 h-40 rounded-lg" src={Evm} alt="" />
                  <div class="mt-4 md:mt-0 md:ml-6 pt-2">
                    <span class="block text-gray-500 mb-2">
                      ERM | Rounak Jha
                    </span>
                    <h4 class="text-xl font-semibold text-gray-900 group-hover:text-blue-900">
                      Looking at ERM from the perspective of a STARTUP
                    </h4>
                  </div>
                </a>
              </div>
            </div>
            <div class="text-left pt-10 ">
              <a
                class="relative group inline-block py-4 px-7 font-semibold text-blue-900 hover:text-orange-50  rounded-full  transition duration-300 overflow-hidden"
                href="/blogs "
              >
                <div class="absolute top-0 right-full w-full h-full bg-blue-900 border-blue-500 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                <span class="relative">See More Articles</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <section
        class=" pt-12 pb-16 lg:pt-24 lg:pb-36 bg-white overflow-hidden"
        style={{ fontFamily: "poppins" }}
      >
        <div class="container px-4 mx-auto">
          <h2 class="mb-4 text-4xl md:text-5xl text-center font-bold font-heading font-heading tracking-px-n leading-tight text-indigo-900">
            Latest from Blog
          </h2>
          <div class="flex flex-wrap   lg:ml-16">
            <div class="w-full md:w-1/2 p-8 ">
              <div class="flex flex-wrap lg:items-center -m-2 bg-slate-50">
                <div class=" w-60 lg:w-40 p-4">
                  <div class="overflow-hidden rounded-xl">
                    <img
                      class="transform hover:scale-105 transition ease-in-out duration-1000"
                      src={biz}
                      alt=""
                    />
                  </div>
                </div>
                <div class="flex-1 p-4">
                  <div class="md:max-w-xs">
                    <div class="flex flex-col justify-between h-full">
                      <div class="mb-6">
                        <p class="mb-1.5 text-sm text-indigo-800 font-medium  tracking-px">
                          Insurance | Raunak Jha
                        </p>
                        <a
                          class="inline-block hover:text-gray-800 hover:underline"
                          href="/blogs/roleofactuaries/"
                        >
                          <h3 class="text-xl font-semibold leading-normal text-indigo-800">
                            Parametric Insurance – Role of Actuaries
                          </h3>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 p-8">
              <div class="flex flex-wrap lg:items-center -m-4 bg-slate-50">
                <div class="w-60 lg:w-40  p-4">
                  <div class="overflow-hidden rounded-xl">
                    <img
                      class="transform hover:scale-105 transition ease-in-out duration-1000"
                      src={make}
                      alt=""
                    />
                  </div>
                </div>
                <div class="flex-1 p-4">
                  <div class="md:max-w-xs">
                    <div class="flex flex-col justify-between h-full">
                      <div class="mb-6">
                        <p class="mb-1.5 text-sm text-indigo-800 font-medium  tracking-px">
                          Underwriting | Vikram Arora
                        </p>
                        <a
                          class="inline-block hover:text-gray-800 hover:underline"
                          href="/blogs/UnderwritingScoringModel"
                        >
                          <h3 class="text-xl font-semibold leading-normal text-indigo-800">
                            Underwriting Scoring Models
                          </h3>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 p-8">
              <div class="flex flex-wrap lg:items-center -m-4 rounded-lg bg-slate-50">
                <div class=" w-60 lg:w-40  p-4">
                  <div class="overflow-hidden rounded-xl">
                    <img
                      class="transform hover:scale-105 transition ease-in-out duration-1000"
                      src={tech}
                      alt=""
                    />
                  </div>
                </div>
                <div class="flex-1 p-4">
                  <div class="md:max-w-xs">
                    <div class="flex flex-col justify-between h-full">
                      <div class="mb-6">
                        <p class="mb-1.5 text-sm text-indigo-800 font-medium  tracking-px">
                          Underwriting | Tamanna Goel
                        </p>
                        <a
                          class="inline-block hover:text-gray-800 hover:underline"
                          href="/blogs/ActuarialPerspective"
                        >
                          <h3 class="text-xl font-semibold leading-normal text-indigo-800">
                            Underwriting – An Introduction from Actuarial
                            Perspective
                          </h3>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 p-8">
              <div class="flex flex-wrap lg:items-center -m-4 bg-slate-50">
                <div class=" w-60 lg:w-40  p-4">
                  <div class="overflow-hidden rounded-xl">
                    <img
                      class="transform hover:scale-105 transition ease-in-out duration-1000"
                      src={acturial}
                      alt=""
                    />
                  </div>
                </div>
                <div class="flex-1 p-4">
                  <div class="md:max-w-xs">
                    <div class="flex flex-col justify-between h-full">
                      <div class="mb-6">
                        <p class="mb-1.5 text-sm text-indigo-800 font-medium  tracking-px">
                          ERM | Raunak Jha
                        </p>
                        <a
                          class="inline-block hover:text-gray-800 hover:underline"
                          href="/blogs/EnterpriseRisk"
                        >
                          <h3 class="text-xl font-semibold leading-normal text-indigo-800">
                            Looking at ERM from the perspective of a STARTUP
                          </h3>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

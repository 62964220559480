import React from "react";

import InfoOne from "../../../static/images/company1.png";
import Infotwo from "../../../static/images/company2.png";
import Infothree from "../../../static/images/company3.png";
import Infofour from "../../../static/images/company4.png";
import Infofive from "../../../static/images/company5.png";
import Infosix from "../../../static/images/company6.png";
import Infoseven from "../../../static/images/company7.png";
import Infoeight from "../../../static/images/moore-logo-01.png";
import Infonine from "../../../static/images/moore-logo-02.png";
import Infoten from "../../../static/images/moore-logo-03.png";
import Infoeleven from "../../../static/images/moore-logo-04.png";
import Infotwel from "../../../static/images/11.jpg";
import Infothirteen from "../../../static/images/even.png";
import Infofourteen from "../../../static/images/2.jpg";
import Infofiveteen from "../../../static/images/3.jpg";
import Infosixteen from "../../../static/images/4.jpg";
import Infoseventeen from "../../../static/images/5.jpg";
import Infoeighteen from "../../../static/images/6.jpg";
import Infonineteen from "../../../static/images/7.jpg";
import Infontwenty from "../../../static/images/8.jpg";
import Infontwentyone from "../../../static/images/9.jpg";
import Infontwentytwo from "../../../static/images/10.jpg";
import Infontwentythree from "../../../static/images/lic.png";
import Infontwentyfour from '../../../static/images/alliencepic.png'
import Infontwentyfive from '../../../static/images/cdata.png'
import Infontwentysix from '../../../static/images/julfar.png'
import Infontwentyseven from '../../../static/images/jyoti.png'
import Infontwentyeight from '../../../static/images/rmdc.png'


import Loadable from "@loadable/component";

const OwlCarousel = Loadable(() => import("react-owl-carousel3"));

const options = {
  loop: false,
  nav: false,
  dots: false,
  autoplay: true,
  smartSpeed: 1000,
  autoplayTimeout: 2000,
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 4,
    },
    1200: {
      items: 5,
    },
  },
};

const SectionThree = () => {
  const [display, setDisplay] = React.useState(false);

  React.useEffect(() => {
    setDisplay(true);
  }, []);

  return (
    <div>
      <div
        className=" bg-white py-14 px-4 lg:px-32 lg:py-20"
        style={{ fontFamily: "Poppins" }}
      >
        <p class=" text-3xl text-blue-900 text-center font-bold  tracking-px">
          Powering next-gen companies
        </p>
        <div></div>
        {display ? (
          <OwlCarousel className=" lg:mt-10 lg:ml-8" {...options}>
            <div className=" m-8">
              <img src={InfoOne} alt="image" />
            </div>

            <div className=" m-8">
              <img src={Infotwo} alt="image" />
            </div>

            <div className=" m-8">
              <img src={Infothree} alt="image" />
            </div>

            <div className=" m-8">
              <img src={Infofour} alt="image" />
            </div>

            {/* <div  className=' m-8'>
                           <img src={Infofive} alt="image" />
                     
                    </div> */}

            <div className=" m-8">
              <img src={Infosix} alt="image" />
            </div>

            {/* <div  className=' m-8'>
                           <img src={Infoseven} alt="image" />
                     
                    </div> */}

            <div className=" m-8 md:pt-5">
              <img src={Infoeight} alt="image" />
            </div>
            <div className=" m-8 md:pt-7">
              <img src={Infonine} alt="image" />
            </div>
            <div className=" m-8">
              <img src={Infoten} alt="image" />
            </div>

            <div className=" m-8 md:mt-5">
              <img src={Infoeleven} alt="image" />
            </div>

            <div className=" m-8 md:mt-5">
              <img src={Infotwel} alt="image" />
            </div>
            <div className=" m-8 md:mt-5">
              <img src={Infothirteen} alt="image" />
            </div>

            <div className=" m-4">
              <img src={Infofourteen} alt="image" />
            </div>

            <div className=" m-8 md:mt-5">
              <img src={Infofiveteen} alt="image" />
            </div>

            <div className=" m-8 md:mt-5">
              <img src={Infosixteen} alt="image" />
            </div>

            <div className=" m-8 md:mt-5">
              <img src={Infoseventeen} alt="image" />
            </div>

            <div className=" m-8 md:mt-5">
              <img src={Infoeighteen} alt="image" />
            </div>

            <div className=" m-8 md:mt-5">
              <img src={Infonineteen} alt="image" />
            </div>

            <div className=" m-8 md:mt-5">
              <img src={Infontwenty} alt="image" />
            </div>

            <div className=" m-8 md:mt-5">
              <img src={Infontwentyone} alt="image" />
            </div>

            <div className=" m-8 md:mt-5">
              <img src={Infontwentytwo} alt="image" />
            </div>

            <div className=" m-8 md:mt-5">
              <img src={Infontwentythree} alt="image" />
            </div>

            <div className=" m-8 md:mt-5">
              <img src={Infontwentyfour} alt="image" />
            </div>

            <div className=" m-8 md:mt-5">
              <img src={Infontwentyfive} alt="image" />
            </div>

            
            <div className=" m-8 md:mt-5">
              <img src={Infontwentysix} alt="image" />
            </div>

                
            <div className=" m-8 md:mt-5">
              <img src={Infontwentyseven} alt="image" />
            </div>

            <div className=" m-8 md:mt-5">
              <img src={Infontwentyeight} alt="image" />
            </div>


          </OwlCarousel>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SectionThree;

// import { info } from 'autoprefixer'
// import React from 'react'
// import Info from '../../../static/images/infoThre.png'
// import InfoOne from '../../../static/images/infoFour.png'

// export default function SectionThree() {
//   return (
//     <div>

//         <section class="relative pt-14 lg:pt-28 pb-36 bg-blueGray-100 overflow-hidden bg-gray-200" style={{fontFamily:'poppins'}}>
//   <img class="absolute top-0 left-0" src="flaro-assets/images/logos/gradient3.svg" alt=""/>
//   <div class="relative z-10 container px-4 mx-auto">
//     <p class="mb-14 text-3xl text-indigo-800 text-center font-bold  tracking-px">Powering next-gen companies</p>
//     <div class="flex flex-wrap max-w-5xl mx-auto -m-3">
//       <div class="w-full md:w-1/2 lg:w-1/4 p-3">
//         <div class="flex items-center justify-center py-8 px-9 h-auto bg-white rounded-3xl">
//           <img src={Info} alt=""/>
//         </div>
//       </div>
//       <div class="w-full md:w-1/2 lg:w-1/4 p-3">
//         <div class="flex items-center justify-center py-8 px-9 h-full bg-white rounded-3xl">
//         <img src={InfoOne} alt=""/>
//         </div>
//       </div>
//       <div class="w-full md:w-1/2 lg:w-1/4 p-3">
//         <div class="flex items-center justify-center py-8 px-9 h-full bg-white rounded-3xl">
//         <img src={Info} alt=""/>
//         </div>
//       </div>
//       <div class="w-full md:w-1/2 lg:w-1/4 p-3">
//         <div class="flex items-center justify-center py-8 px-9 h-full bg-white rounded-3xl">
//         <img src={InfoOne} alt=""/>
//         </div>
//       </div>
//       <div class="w-full md:w-1/2 lg:w-1/4 p-3">
//         <div class="flex items-center justify-center py-8 px-9 h-full bg-white rounded-3xl">
//         <img src={Info} alt=""/>
//         </div>
//       </div>
//       <div class="w-full md:w-1/2 lg:w-1/4 p-3">
//         <div class="flex items-center justify-center py-8 px-9 h-full bg-white rounded-3xl">
//         <img src={InfoOne} alt=""/>
//         </div>
//       </div>
//       <div class="w-full md:w-1/2 lg:w-1/4 p-3">
//         <div class="flex items-center justify-center py-8 px-9 h-full bg-white rounded-3xl">
//         <img src={Info} alt=""/>
//         </div>
//       </div>
//       <div class="w-full md:w-1/2 lg:w-1/4 p-3">
//         <div class="flex items-center justify-center py-8 px-9 h-full bg-white rounded-3xl">
//         <img src={InfoOne} alt=""/>
//         </div>
//       </div>
//     </div>
//   </div>
// </section>
//     </div>
//   )
// }

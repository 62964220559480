import React from "react";
import Navbar from "../components/navbar";
import Header from "../components/header";
import Footer from "../components/footer";
import { FloatingWhatsApp } from "react-floating-whatsapp";

import Team from "../components/AboutUs/team";
import FeaturesOne from "../components/wrexa/features";
import SectionThree from "../components/Homepage/SectionThree";
import SectionBlog from "../components/Homepage/SectionBlog";
import SectionExplainer from "../components/Homepage/SectionExplainer";
import Testimonial from "../components/Homepage/SectionTestimonial";
import Sectionservices from "../components/Homepage/Sectionservices";
import LogoOne from "../../static/images/favicon.png";

export default function Home() {
  return (
    <div>
      <div>
        <FloatingWhatsApp
          phoneNumber="+91 9930522133"
          accountName="RJ Actuaries & Consultants"
          allowEsc
          allowClickAway
          notification
          notificationSound
          avatar={LogoOne}
        />
      </div>

      <Navbar />
      <Header />
      {/* <FeaturesOne /> */}
      <Sectionservices />
      <SectionExplainer />
      <SectionBlog />
     
      <SectionThree />
      {/* <Team/> */}
      {/* <Testimonial /> */}

      <Footer />
    </div>
  );
}

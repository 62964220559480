import React from "react";
import headone from "../../../static/images/head1.png";
import headtwo from "../../../static/images/head2.png";
import headthree from "../../../static/images/head3.png";
import headfour from "../../../static/images/head4.png";
import headfive from "../../../static/images/head5.png";
import headsix from "../../../static/images/head6.png";
import Emp from "../../../static/images/empbenefit.jpg";
import Risk from "../../../static/images/risk.jpg";
import Acu from "../../../static/images/Actuarial1.jpg";

export default function Sectionservices() {
  return (
    <div>
      <section>
        <section
          class="relative pt-10 md:pt-20 pb-10 lg:pb-32 overflow-hidden bg-white"
          style={{ fontFamily: "Poppins" }}
        >
          <img
            class="absolute top-0 right-0"
            src="saturn-assets/images/features/star-element-right.png"
            alt=""
          />
          <div class="relative container px-4 mx-auto">
            <div class="max-w-md lg:max-w-7xl mx-auto">
              <div class="flex flex-wrap -mx-4 mb-18 items-center">
                <div class="w-full lg:w-2/3 xl:w-1/2 px-4 md:mb-12 lg:mb-0">
                  <div class="max-w-lg">
                    {/* <span class="inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full">FEATURES</span> */}
                    <h1 class="font-heading py-3 pb-8 text-4xl xs:text-4xl md:text-4xl font-bold text-blue-900">
                      <span>Our Services</span>
                      {/* <span class="font-serif italic">Studies</span> */}
                    </h1>
                  </div>
                </div>
                <div class="w-full lg:w-1/3 xl:w-1/2 px-4 pb-4">
                  <div class="max-w-lg lg:ml-auto">
                    <p class="text-lg text-blue-800">
                      RJ Actuaries & Consultants offers comprehensive actuarial
                      services, including valuation, risk assessment, and
                      strategic consulting.
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap -mx-4">
                <div class="w-full lg:w-1/3 px-4 mb-12 lg:mb-0">
                  <div>
                    <img
                      class="block mb-6 w-full h-[280px] rounded-md"
                      src={Acu}
                      alt=""
                    />
                    <div>
                      <h3 class="text-xl  font-semibold mb-5 text-gray-900">
                        Actuarial Consulting and Valuation
                      </h3>
                      <a
                        class="inline-flex items-center justify-center bg-blue-800 w-full lg:w-auto px-5 py-3 text-sm font-semibold text-white rounded-full border border-gray-100 hover:border-orange-900 transition duration-200"
                        href="/ActuarialConsulting"
                      >
                        <span class="mr-3">Learn More</span>
                        <svg
                          width="13"
                          height="13"
                          viewbox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.9199 0.62C12.8185 0.375651 12.6243 0.181475 12.3799 0.0799999C12.2597 0.028759 12.1306 0.00157999 11.9999 0H1.99994C1.73472 0 1.48037 0.105357 1.29283 0.292893C1.1053 0.48043 0.999939 0.734784 0.999939 1C0.999939 1.26522 1.1053 1.51957 1.29283 1.70711C1.48037 1.89464 1.73472 2 1.99994 2H9.58994L1.28994 10.29C1.19621 10.383 1.12182 10.4936 1.07105 10.6154C1.02028 10.7373 0.994141 10.868 0.994141 11C0.994141 11.132 1.02028 11.2627 1.07105 11.3846C1.12182 11.5064 1.19621 11.617 1.28994 11.71C1.3829 11.8037 1.4935 11.8781 1.61536 11.9289C1.73722 11.9797 1.86793 12.0058 1.99994 12.0058C2.13195 12.0058 2.26266 11.9797 2.38452 11.9289C2.50638 11.8781 2.61698 11.8037 2.70994 11.71L10.9999 3.41V11C10.9999 11.2652 11.1053 11.5196 11.2928 11.7071C11.4804 11.8946 11.7347 12 11.9999 12C12.2652 12 12.5195 11.8946 12.707 11.7071C12.8946 11.5196 12.9999 11.2652 12.9999 11V1C12.9984 0.869323 12.9712 0.740222 12.9199 0.62Z"
                            fill="#FFFFFF"
                          ></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-1/3 px-4 mb-12 lg:mb-0">
                  <div>
                    <img
                      class="block mb-6 w-full h-100 rounded-md h-[280px]"
                      src={Emp}
                      alt=""
                    />
                    <div>
                      <h3 class="text-xl font-semibold mb-5 text-gray-900">
                        Employee Benefits Evaluation
                      </h3>
                      <a
                        class="inline-flex items-center justify-center bg-blue-800 w-full lg:w-auto px-5 py-3 text-sm font-semibold text-white rounded-full border border-gray-100 hover:border-orange-900 transition duration-200"
                        href="/EmployeeBenefits"
                      >
                        <span class="mr-3">Learn More</span>
                        <svg
                          width="13"
                          height="13"
                          viewbox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.9199 0.62C12.8185 0.375651 12.6243 0.181475 12.3799 0.0799999C12.2597 0.028759 12.1306 0.00157999 11.9999 0H1.99994C1.73472 0 1.48037 0.105357 1.29283 0.292893C1.1053 0.48043 0.999939 0.734784 0.999939 1C0.999939 1.26522 1.1053 1.51957 1.29283 1.70711C1.48037 1.89464 1.73472 2 1.99994 2H9.58994L1.28994 10.29C1.19621 10.383 1.12182 10.4936 1.07105 10.6154C1.02028 10.7373 0.994141 10.868 0.994141 11C0.994141 11.132 1.02028 11.2627 1.07105 11.3846C1.12182 11.5064 1.19621 11.617 1.28994 11.71C1.3829 11.8037 1.4935 11.8781 1.61536 11.9289C1.73722 11.9797 1.86793 12.0058 1.99994 12.0058C2.13195 12.0058 2.26266 11.9797 2.38452 11.9289C2.50638 11.8781 2.61698 11.8037 2.70994 11.71L10.9999 3.41V11C10.9999 11.2652 11.1053 11.5196 11.2928 11.7071C11.4804 11.8946 11.7347 12 11.9999 12C12.2652 12 12.5195 11.8946 12.707 11.7071C12.8946 11.5196 12.9999 11.2652 12.9999 11V1C12.9984 0.869323 12.9712 0.740222 12.9199 0.62Z"
                            fill="#FFFFFF"
                          ></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-1/3 px-4">
                  <div>
                    <img
                      class="block mb-6 w-full h-[280px] rounded-md"
                      src={Risk}
                      alt=""
                    />
                    <div>
                      <h3 class="text-xl font-semibold mb-5 text-gray-900">
                        Enterprise Risk Management
                      </h3>
                      <a
                        class="inline-flex items-center justify-center bg-blue-800 w-full lg:w-auto px-5 py-3 text-sm font-semibold text-white rounded-full border border-gray-100 hover:border-orange-900 transition duration-200"
                        href="/BusinessConsulting"
                      >
                        <span class="mr-3">Learn More</span>
                        <svg
                          width="13"
                          height="13"
                          viewbox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.9199 0.62C12.8185 0.375651 12.6243 0.181475 12.3799 0.0799999C12.2597 0.028759 12.1306 0.00157999 11.9999 0H1.99994C1.73472 0 1.48037 0.105357 1.29283 0.292893C1.1053 0.48043 0.999939 0.734784 0.999939 1C0.999939 1.26522 1.1053 1.51957 1.29283 1.70711C1.48037 1.89464 1.73472 2 1.99994 2H9.58994L1.28994 10.29C1.19621 10.383 1.12182 10.4936 1.07105 10.6154C1.02028 10.7373 0.994141 10.868 0.994141 11C0.994141 11.132 1.02028 11.2627 1.07105 11.3846C1.12182 11.5064 1.19621 11.617 1.28994 11.71C1.3829 11.8037 1.4935 11.8781 1.61536 11.9289C1.73722 11.9797 1.86793 12.0058 1.99994 12.0058C2.13195 12.0058 2.26266 11.9797 2.38452 11.9289C2.50638 11.8781 2.61698 11.8037 2.70994 11.71L10.9999 3.41V11C10.9999 11.2652 11.1053 11.5196 11.2928 11.7071C11.4804 11.8946 11.7347 12 11.9999 12C12.2652 12 12.5195 11.8946 12.707 11.7071C12.8946 11.5196 12.9999 11.2652 12.9999 11V1C12.9984 0.869323 12.9712 0.740222 12.9199 0.62Z"
                            fill="#FFFFFF"
                          ></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}

import React from "react";
import Icons2 from "../../../static/images/icons2.png";

export default function SectionExplainer() {
  return (
    <div>
      <section
        class="relative py-8 pb-8 overflow-hidden bg-white"
        style={{ fontFamily: "Poppins" }}
      >
        <img
          class="absolute top-0 right-0"
          src="saturn-assets/images/cta/light-blue-right.png"
          alt=""
        />
        <div class="relative container px-4 mx-auto">
          <div class="max-w-7xl mx-auto">
            <div class="flex flex-wrap -mx-4 items-center">
              <div class="w-full lg:w-1/2 xl:w-1/3 px-4 mb-20 lg:mb-0">
                <div class="max-w-2xl">
                  <h1 class="font-heading text-3xl sm:text-4xl font-bold text-blue-900 mb-10">
                    <span>
                      Make better business decisions through data driven
                      insights
                    </span>
                    {/* <span class="font-serif italic">internationally</span> */}
                  </h1>
                  <ul class="mb-10">
                    <li class="flex mb-4 items-center">
                      <div class="flex flex-shrink-0 mr-3 items-center justify-center w-6 h-6 bg-blue-900 rounded-full">
                        <svg
                          width="12"
                          height="9"
                          viewbox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.6666 1.5L4.24992 7.91667L1.33325 5"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      <span class="text-gray-600">
                        Expertise in Insurance Consulting
                      </span>
                    </li>
                    <li class="flex mb-4 items-center">
                      <div class="flex flex-shrink-0 mr-3 items-center justify-center w-6 h-6 bg-blue-900 rounded-full">
                        <svg
                          width="12"
                          height="9"
                          viewbox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.6666 1.5L4.24992 7.91667L1.33325 5"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      <span class="text-gray-600">Client Focused</span>
                    </li>
                    <li class="flex items-center mb-4">
                      <div class="flex flex-shrink-0 mr-3 items-center justify-center w-6 h-6 bg-blue-900 rounded-full">
                        <svg
                          width="12"
                          height="9"
                          viewbox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.6666 1.5L4.24992 7.91667L1.33325 5"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      <span class="text-gray-600">Project Management</span>
                    </li>
                    <li class="flex items-center">
                      <div class="flex flex-shrink-0 mr-3 items-center justify-center w-6 h-6 bg-blue-900 rounded-full">
                        <svg
                          width="12"
                          height="9"
                          viewbox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.6666 1.5L4.24992 7.91667L1.33325 5"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      <span class="text-gray-600">Effective Communication</span>
                    </li>
                  </ul>
                  <div class="sm:flex items-center">
                    <a
                      class="relative group inline-block w-full sm:w-auto mb-3 sm:mb-0 sm:mr-4 py-4 px-6 text-center text-white font-semibold bg-blue-900 rounded-full overflow-hidden"
                      href="/services"
                    >
                      <div class="absolute top-0 right-full w-full h-full bg-blue-500 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                      <span class="relative">Join Now</span>
                    </a>
                    <a
                      class="inline-flex w-full sm:w-auto py-4 px-6 items-center justify-center text-blue-900 font-semibold border border-gray-200 hover:border-blue-900 rounded-full transition duration-200"
                      href="#"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-1/2 xl:w-1/3 px-4">
                <div class="flex flex-wrap -mx-4 -mb-10 justify-between lg:block lg:max-w-xs lg:ml-auto">
                  <div class="w-full sm:w-1/2 lg:w-full px-4 mb-10">
                    <div class="max-w-xs">
                      <div class="flex w-14 h-14 mb-6 items-center justify-center rounded-full">
                        <img src={Icons2} alt="" />
                      </div>
                      <h5 class="text-xl font-semibold text-gray-900 mb-2">
                        Intuitive Reporting Solutions
                      </h5>
                      <p class="text-gray-500">
                        Our reports, powered by the latest tools and technology,
                        provide clear insights, enabling quick and effective
                        decision-making.
                      </p>
                    </div>
                  </div>
                  <div class="w-full sm:w-1/2 lg:w-full px-4 mb-10">
                    <div class="max-w-xs">
                      <div class="flex w-14 h-14 mb-6 items-center justify-center rounded-full">
                        <img src={Icons2} alt="" />
                      </div>
                      <h5 class="text-xl font-semibold text-gray-900 mb-2">
                        Strategic Decision-Making Tools
                      </h5>
                      <p class="text-gray-500">
                        Our simple yet powerful tools assist in analyzing market
                        trends, optimizing resources, and identifying growth
                        opportunities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-1/2 xl:w-1/3 px-4">
                <div class="flex flex-wrap -mx-4 -mb-10 justify-between lg:block lg:max-w-xs lg:ml-auto">
                  <div class="w-full sm:w-1/2 lg:w-full px-4 mb-10">
                    <div class="max-w-xs">
                      <div class="flex w-14 h-14 mb-6 items-center justify-center rounded-full">
                        <img src={Icons2} alt="" />
                      </div>
                      <h5 class="text-xl font-semibold text-gray-900 mb-2">
                        Simplified Data with Machine Learning
                      </h5>
                      <p class="text-gray-500">
                        With machine learning capabilities, we simplify complex
                        data, uncovering patterns and trends to drive actionable
                        insights for your business.
                      </p>
                    </div>
                  </div>
                  <div class="w-full sm:w-1/2 lg:w-full px-4 mb-10">
                    <div class="max-w-xs">
                      <div class="flex w-14 h-14 mb-6 items-center justify-center rounded-full">
                        <img src={Icons2} alt="" />
                      </div>
                      <h5 class="text-xl font-semibold text-gray-900 mb-2">
                        Tailored Solutions for Your Business
                      </h5>
                      <p class="text-gray-500">
                        Our solutions are highly specific to your needs and
                        goals, delivering customized reports and analytics tools
                        to drive success.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class=" pt-16 lg:pt-24 lg:pb-8  bg-indigo-50 overflow-hidden"
        style={{ fontFamily: "poppins" }}
      >
        <div class="container  lg:px-4 mx-auto">
          <h2 class=" ml-12 mb-8 text-blue-900 text-2xl md:text-4xl lg:ml-24 font-bold font-heading tracking-px-n leading-tight md:max-w-lg">
            Our skills match perfectly to your requirements
          </h2>
          <div class="flex flex-wrap m-12">
            <div class="w-full md:w-1/2 lg:w-1/4 p-8">
              <div class="flex flex-wrap items-center mb-2 lg:mb-7 -m-2">
                <div class="w-auto p-2">
                  <div class="relative w-14 h-14 text-2xl font-bold font-heading bg-indigo-100 rounded-full">
                    <span class="absolute top-1/2 left-1/2  transform -translate-x-1/2 -translate-y-1/2">
                      1
                    </span>
                  </div>
                </div>
                <div class="flex-1 p-2">
                  <div class="w-full h-px bg-gray-200"></div>
                </div>
              </div>
              <h3 class="text-lg text-blue-900 font-semibold leading-normal md:max-w-xs">
                We deliver intuitive reports through latest tools and technology
              </h3>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/4 p-8">
              <div class="flex flex-wrap items-center mb-2 lg:mb-7 -m-2">
                <div class="w-auto p-2">
                  <div class="relative w-14 h-14 text-2xl text-white font-bold bg-sky-600 rounded-full">
                    <img
                      class="absolute top-0 left-0"
                      src="flaro-assets/images/how-it-works/gradient.svg"
                      alt=""
                    />
                    <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      2
                    </span>
                  </div>
                </div>
                <div class="flex-1 p-2">
                  <div class="w-full h-px bg-gray-200"></div>
                </div>
              </div>
              <h3 class="text-lg text-blue-900 font-semibold leading-normal md:max-w-xs">
                Our simple strategic applications can help you make business
                decisions
              </h3>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/4 p-8">
              <div class="flex flex-wrap items-center mb-2 lg:mb-7 -m-2">
                <div class="w-auto p-2">
                  <div class="relative w-14 h-14 text-2xl font-bold font-heading bg-indigo-100 rounded-full">
                    <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      3
                    </span>
                  </div>
                </div>
                <div class="flex-1 p-2">
                  <div class="w-full h-px bg-gray-200"></div>
                </div>
              </div>
              <h3 class="text-lg text-blue-900 font-semibold leading-normal md:max-w-xs">
                With machine learning capabilities, your complex data can be
                simplified
              </h3>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/4 p-8">
              <div class="flex flex-wrap items-center mb-2 lg:mb-7 -m-2">
                <div class="w-auto p-2">
                  <div class="relative w-14 h-14 text-2xl font-bold font-heading bg-indigo-100 rounded-full">
                    <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      4
                    </span>
                  </div>
                </div>
                <div class="flex-1 p-2">
                  <div class="w-full h-px bg-gray-200"></div>
                </div>
              </div>
              <h3 class="text-lg text-blue-900 font-semibold leading-normal md:max-w-xs">
                Our solutions are highly specific to your needs, and business
                goals
              </h3>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
